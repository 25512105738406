<template>
  <detailed-view-container
    questionnaireTitle="Linear Analog Self Assessment (LASA)"
    :questionnaire="questionnaire"
  >
    <template v-slot:questionnnairePanel>
      <LASAQuestionnairePanels :questionnaire="questionnaire" />
    </template>
  </detailed-view-container>
</template>

<script>
const components = {
  LASAQuestionnairePanels: () =>
    import(
      "@/components/views/dashboard/members/id/partial/questionnaires/components/LASAQuestionnairePanels.vue"
    ),
  DetailedViewContainer: () =>
    import(
      "@/components/views/dashboard/members/id/partial/questionnaires/DetailedViewContainer.vue"
    ),
};

export default {
  name: "LASA",

  props: {
    questionnaire: {
      type: Object,
      default: () => {},
    },
  },

  components,
};
</script>